@import '../../../css/variables';

.view-button {
    font-size: 14px !important;
}

.saved-card {
    padding: 12px 16px;
    margin-bottom: 8px;
    border: 2px solid transparent;
}

.saved-card-selected {
    padding: 12px 16px;
    margin-bottom: 8px;
    border: 2px solid $teal;
}

.saved-card-click {
    cursor: pointer;
}

.saved-filter-chip {
    display: inline-block !important;
}

.save-modal-input {
    width: 100%;
    border-radius: 4px;
    height: 40px;
    border: solid 2px $gray-400;
}

.save-modal {
    padding: 16px 16px 32px 32px;
}

.saved-no {
    border-color: $teal !important;
    color: $indigo;
    font-size: 14px !important;
}

.saved-no:hover {
    background: $teal !important;
}

.save-modal-input:focus {
    outline: none !important;
    border-color: $teal;
}

.save-search-button {
    font-size: 14px !important;
}
