@import '../../css/variables';
@import '../../css/typography';
@import '../../css/mixins';

.main-card {
    background: $white;
    box-sizing: border-box;
    padding: 25px;
}

.container-wrap {
    display: flex;
    flex-wrap: wrap;
}

.account-menu {
    background-color: $white;
    height: min-content;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 81px;
    width: 100%;
    min-width: 110px;
}

.accountMenuHolder {
    padding-left: 0px !important;
    padding-right: 0px !important;
    background-color: $white;
    height: 100v;
}

.nav-block {
    display: block;
    width: 100%;
    padding: 6px 0;
    &:hover {
        font-weight: bold;
        text-decoration: none;
    }
}

.navLinkItem {
    margin-left: 16px;
}

.navLinkButton {
    width: 100%;
    margin: 0;
    padding: 0;
    text-align: left;
    &:focus {
        border: 0 !important;
        outline: 0 !important;
    }
}

.subLinkItem {
    border-left: 2px solid #efefef;
    margin-left: 50px;
    padding: 8px 0 8px 10px;
}

.section-subheader {
    margin-left: 15px;
    li {
        margin-bottom: 4px;
    }
}

.verticalNavBarHolder {
    display: block !important;
}

.verticalNavBar {
    margin: 0;
    padding: 15px 10px 15px 20px !important;
    background: #fff;
    border: 0;
}

.activeCard {
    border-right: 4px solid $teal !important;
    a,
    button {
        display: flex;
        align-items: center;
    }
}

.accountNav {
    a,
    button {
        display: flex;
        align-items: center;
    }
}

.accountSvgs {
    width: 18px;
    height: 18px;
}

.membersSvg {
    width: 22px;
    height: 22px;
    fill: none;
    padding-left: 0px;
    padding-top: 0px;
    margin-left: 1px;
}

.dashboardPadding {
    @include mq('tablet-small', max) {
        padding-left: 10% !important;
    }
    @include mq('phablet', max) {
        padding-left: 15% !important;
    }
    @include mq('phone-wide', max) {
        padding-left: 30% !important;
    }
}

.accountHeader {
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.09);
    background-color: $white;
    width: 100%;
    padding: 24px;
    &-alt {
        border-top: 1px solid#e2e2e2;
    }
}

.accountBanner {
    background-color: $red-light;
    color: $dark-red;
    border-radius: 4px;
    width: 100%;
    min-height: 40px;
    margin-left: 0px !important;
    margin-bottom: 16px !important;
    align-content: center;
}

.kpiContainer {
    width: 100%;
}

.kpiClass {
    @include mq('tablet', max) {
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    @include mq('phone-wide', max) {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}

.dataAccessTabs {
    align-items: center;
    justify-content: center;
}

.toolsButtons {
    text-align: center;
}

.truncate {
    width: 380px;
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.searchTermLink {
    white-space: pre-wrap;
}

.kpiCard {
    height: 90px;
    padding: 10px;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.09);
    background-color: #ffffff;
}

.kpiCard {
    @include mq('desktop', max) {
        height: 100px !important;
        padding: 10px !important;
        box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.09) !important;
        background-color: #ffffff !important;
    }
    @include mq('tablet-wide', max) {
        height: 100px !important;
        padding: 5px !important;
        box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.09) !important;
        background-color: #ffffff !important;
    }
}

#dateDropdown input,
select,
optgroup,
textarea {
    font-family: inherit;
    font-size: inherit !important;
    color: inherit !important;
}

#dateDropdown {
    background-color: $white !important;
    border-color: $gray-400 !important;
    min-width: 160px;
}

#metadataButton {
    width: 299px;
    height: 40px;
}

#advancedSearchButton {
    width: 240px;
    height: 40px;
}

#userguideButton {
    width: 105px;
    height: 40px;
    background-color: $gray-200;
}

#userguideButton:focus {
    box-shadow: none !important;
}

#serviceDeskButton {
    width: 116px;
    height: 40px;
    background-color: $gray-200;
}

#serviceDeskButton:focus {
    box-shadow: none !important;
}

.noTextDecoration {
    text-decoration: none;
}

#thisTag {
    border-radius: 5px;
    width: 48px;
    height: 24px;
    background-color: #3db28c;
    text-align: center;
    display: inline-block;
}

.datasetCard.card {
    border: 0 !important;
    border-left: 4px solid #fff !important;
    border-radius: 0 !important;
    background-color: $white !important;
    border-bottom: 1px solid $background !important;
    padding: 16px 24px 16px 20px !important;
}

.datasetCard.card.activeCard {
    border-left: 4px solid $gray-400 !important;
}

.datasetCard.card-header {
    border-radius: 0 !important;
    background-color: $white !important;
    border: 0 !important;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0px !important;
    transition: linear padding 250ms;
    cursor: pointer;
}

.datasetCard.card-body {
    padding: 4px 0px 0px 45px !important;
}

.teamSelectorMenu .dropdown-item {
    white-space: normal;
}

.custom-dropdown {
    max-width: 375px;
    width: 100%;
    background: none;
    border: 2px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    padding: 0.75rem 0.75rem;
    &:focus-within {
        border: solid 2px $teal;
    }

    button {
        display: flex;
        flex-flow: row;
        align-items: center;
        margin: 0;
        padding: 0;
        width: calc(100% - 15px);
        background: transparent;
        background-image: url(../../images/stock.svg);
        background-position-x: 99%;
        background-position-y: 45%;
        background-repeat: no-repeat;

        @include font-source(13px, $gray-700, $font-weight-semibold);
        text-align: left;
        border: 0;
        outline: 0;
        &:after {
            margin-left: 90%;
            display: none;
            background-image: url(../../images/stock.svg);
        }
        &:focus-within {
            outline: 0;
            box-shadow: none;
            background-image: url(../../images/stock-rotated.svg);
        }
    }

    .dropdown-menu {
        width: 100%;
    }

    .dropdown-menu.show {
        margin-left: -13px;
        margin-top: 13px;
    }
}
.padding-right-0 {
    padding-right: 0px;
}

.sectorTypeahead {
    &:focus-within {
        border: solid 2px $teal !important;
    }
}

.colOneTerm {
    float: left;
    padding-left: 15px;
}

.resultBox {
    width: 100%;
    background-color: $white;
    padding: 12px;
    margin-left: 0px !important;
    border-top: #e7e9ed solid 1px;
}

.resultsPadding {
    padding: 12px 12px 12px 12px !important;
}

.dashboardHeader {
    background-color: $white;
    width: 100%;
    padding: 12px 12px;
    margin-left: 0px !important;
}

.noDars {
    text-align: center;
    margin-top: 0.5px;
}

.teamSelectorMenu {
    width: 100%;
    transform: translate(0px, 50px) !important;
    box-sizing: border-box;

    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 400px;
    word-wrap: break-word;
}

.teamSelectorHeader {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    padding: 13px 16px 13px 16px;
    border-bottom: 1px solid #e7e9ed;
    border-top: 1px solid #e7e9ed;
}

.eyeColumn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.eye {
    background: white;
    height: 25px;
    width: 27px;
    padding: 0px;
    outline: none;
    border-style: none;
}

.eye:focus {
    outline: 0;
}

.eyeFaded {
    opacity: 0.7;
}

.entityDashboardPagination {
    float: right;
    margin-right: 15px;
}

.tm {
    &-wrapper {
        margin: 1.2rem 0;
    }

    &-notification {
        display: flex;
        align-items: center;
    }

    &-switch {
        display: flex;
        align-items: center;
        padding-right: 15px;
    }
}

button.dropdown-toggle.btn.btn-outline-secondary::after {
    position: relative;
    top: 4.5px;
}

.grey-circle-border {
    padding: 2px;
    border: solid 2px #b3b8bd;
    border-radius: 50%;
    display: inline-flex;
}

.memberOfValue {
    margin-left: -20px;
}

.addEditTeamBorder {
    border-top: 1px solid $gray-pale;
}

.datasetHeader {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 10px;
    padding: 25px 25px 0 25px;

    @media (min-width: $bp-large) {
        flex-flow: row wrap;
        justify-content: space-between;
    }

    &-title {
        @media (min-width: $bp-large) {
            width: 55%;
        }
        @media (min-width: $bp-largest) {
            width: 65%;
        }

        h1 {
            font-size: 18px;
            line-height: 22px;
        }
    }

    &-status {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
    }

    .sla {
        margin-right: 0;
        margin-bottom: 0;
    }
}
